import { defineStore } from 'pinia'
import { FileModel } from '~/types'

export const useFileSelection = defineStore('selectedFiles', () => {
  const files = ref<Map<number, FileModel>>(new Map())

  const select = (value: FileModel) => {
    files.value.set(value.id, { ...value })
  }

  const unSelect = (value: FileModel) => {
    files.value.delete(value.id)
  }
  const unSelectAll = () => {
    files.value.clear()
  }

  const toggle = (value: FileModel) => {
    const exist = files.value.has(value.id)
    exist ? unSelect({ ...value }) : select({ ...value })
  }

  return { files, select, unSelect, toggle, unSelectAll }
})
